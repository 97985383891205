@media (max-width: 1600px) {
    .calcSection__Form {
        margin: 0 20px;
        position: relative;
    }
    .calcSection__Content h1 {
        font-size: 53px;
    }
    .resultModal .modal-dialog {
        max-width: 37%;
    }
}
@media (max-width: 1550px) {
    .takeaway__Img img {
        width: 100%;
    }
    .takeaway__Content ul li {
        margin-bottom: 20px;
    }
}

@media (max-width: 1440px) {
    .calcSection {
        padding: 160px 90px 80px;
    }
    .calcSection__Form {
        margin: 0;
    }
    .calcSection__Content h1 {
        font-size: 48px;
    }
    .takeaway {
        padding: 60px 90px;
    }
    .takeaway__Content h2 {
        font-size: 50px;
    }
    .video {
        padding: 40px 90px;
    }
}

@media (max-width: 1366px) {
    .calcSection__Content h1 {
        font-size: 45px;
    }
    .takeaway__Content h2, .helpus .helpus__Content h2 {
        font-size: 45px;
    }
    .takeaway__Content ul li {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .resultModal .modal-dialog {
        max-width: 42%;
    }
}
@media (max-width: 991px) {
    .navbar {
        padding: 10px;
    }
    .navbar .navbar-toggler {
        outline: none;
        box-shadow: none;
        border: none;
    }
    .calcSection__Form {
        margin-top: 20px;
    }
    .calcSection .leftBubble, .calcSection .rightBubble {
        display: none;
    }
    .calcSection {
        padding: 80px 50px;
    }
    .takeaway {
        padding: 60px 40px;
    }
    .takeaway__Img {
        text-align: center;
        margin-bottom: 20px;
    }
    .takeaway__Img img {
        width: 40%;
    }
    .video {
        padding: 40px;
    }
    .video .video__Content video {
        margin-top: 20px;
    }
    .resultModal .modal-dialog {
        max-width: 80%;
    }
}

@media (max-width: 767px) {
    .calcSection {
        padding: 50px 0 0;
    }
    .calcSection__Content h1 {
        font-size: 30px;
    }
    .navbar .navbar-brand img {
        width: 100%;
        max-width: 230px;
    }
    .calcSection__Form .form-group .btn-calc {
        padding: 10px;
    }
    .takeaway {
        padding: 60px 0;
    }
    .video .video__Content h2 {
        font-size: 45px;
    }
    .footer__Content {
        text-align: center;
    }
    .footer__Content ul {
        text-align: center;
        margin-top: 10px;
    }
    .resultModal .modal-dialog {
        max-width: 100%;
    }
}