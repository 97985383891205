/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

/* variables */
:root {
    /* colours */
    --primary-color: #23B432;
    --secondary-color: #263647;
    --white-color: #fff;
    --red-color: #f00;
    --bg-grey: #F5F5F5;
    --bg-white: #fff;

    /* fonts */
    --semi-bold: 600;
    --extra-bold: 900;

    /* positions */
    --relative: relative;
    --absolute: absolute;

    /* alignment */
    --center: center;
    --right: right;
    --left: left;

    /* display */
    --flex: flex;
    --inline-block: inline-block;
    --none: none;
}

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
}

/* navigation bar starts */
.navbar {
    box-shadow: 6px -9px 74px rgba(0, 0, 0, 0.11);
    background-color: var(--bg-white);
    padding: 8px 100px;
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 11;
}
.navbar .navbar-nav .nav-item .nav-link {
    padding: 18px 30px;
    color: var(--secondary-color);
    transition: all 0.3s ease-in-out;
}
.navbar .navbar-nav .nav-item .nav-link.active, .navbar .navbar-nav .nav-item .nav-link:hover {
    color: var(--primary-color);
}
/* navigation bar ends */

/* calc section starts */
.calcSection {
    padding: 170px 150px 80px;
    position: var(--relative);
}
.calcSection .leftBubble {
    position: var(--absolute);
    left: 0;
}
.calcSection .rightBubble {
    position: var(--absolute);
    right: 0;
    top: 60%;
}
/* .calcSection__Content {
    height: 100%;
    display: var(--flex);
    flex-direction: column;
    justify-content: var(--center);
} */
.calcSection__Content h1 {
    font-size: 55px;
    color: var(--secondary-color);
    margin-bottom: 30px;
}
.calcSection__Content h1 span {
    color: var(--primary-color);
    font-weight: var(--extra-bold);
}
.calcSection__Content p {
    color: var(--secondary-color);
}
.calcSection__Content p a {
    color: var(--primary-color);
    text-decoration: var(--none);
}
.calcSection__Content p span {
    color: var(--primary-color);
}
.calcSection__Content p span.red {
    color: var(--red-color);
}
.calcSection__Form {
    background: var(--bg-white);
    border: 1px solid var(--bg-white);
    box-shadow: 6px -9px 74px rgba(0, 0, 0, 0.11);
    padding: 30px;
    margin: 0 50px;
}
.calcSection__Form h3 {
    text-align: var(--center);
    font-weight: var(--semi-bold);
    margin: 20px 0;
    color: var(--secondary-color);
}
.calcSection__Form h2 {
    text-align: var(--center);
    font-weight: var(--extra-bold);
    color: var(--secondary-color);
    font-size: 40px;
}
.calcSection__Form .form-group {
    margin-bottom: 30px;
}
.calcSection__Form .form-group label {
    margin-bottom: 10px;
    font-weight: var(--semi-bold);
    color: var(--secondary-color);
    position: var(--relative);
}
.calcSection__Form .form-group label .tooltipInnter {
    background-color: #333;
    color: var(--white-color);
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 5px;
    top: -32px;
    position: absolute;
    width: 160px;
    left: 0;
    text-align: var(--center);
    display: none;
}
.calcSection__Form .form-group label .tooltipInnter::before {
    position: var(--absolute);
    content: " ";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #333;
    bottom: -7px;
    left: 50%;
    transform: translate(-50%, 0);
}
.calcSection__Form .form-group label:hover .tooltipInnter {
    display: inline-block;
}
.calcSection__Form .form-group label.error {
    color: var(--red-color);
    margin: 0;
    font-weight: 500;
}
.calcSection__Form .form-group label span {
    color: var(--red-color);
}
.calcSection__Form .form-group .form-control {
    height: 45px;
    border: 1px solid rgba(38, 54, 71, 0.2);
    border-radius: 4px;
    box-shadow: var(--none);
    color: var(--secondary-color);
}
.calcSection__Form .form-group select {
    background-image: url(../img/downArrow.svg);
    background-size: 10px;
    background-position: 98% 50%;
    background-repeat: no-repeat;
}
.calcSection__Form .form-group .form-check .form-check-label {
    font-weight: 500;
}
.calcSection__Form .form-group .form-check .form-check-input {
    box-shadow: var(--none);
}
.calcSection__Form .form-group .form-check .form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}
.calcSection__Form .form-group .btn-calc {
    background: linear-gradient(180deg, #23B432 0%, #12951E 100%);
    border-radius: 4px;
    color: var(--bg-white);
    padding: 10px 20px;
    margin-top: 20px;
    font-weight: var(--semi-bold);
}
/* calc section ends */


/* takeaway section starts */
.takeaway {
    padding: 60px 150px;
}
.takeaway__Img img {
    width: 90%;
}
.takeaway__Content h2 {
    font-size: 60px;
    color: var(--secondary-color);
    margin-bottom: 50px;
}
.takeaway__Content h2 span {
    color: var(--primary-color);
    font-weight: var(--extra-bold);
}
.takeaway__Content ul li {
    margin-bottom: 30px;
    color: var(--secondary-color);
    list-style-type: var(--none);
    position: relative;
}
.takeaway__Content ul li::before {
    position: absolute;
    content: " ";
    width: 20px;
    height: 20px;
    background-image: url(../img/marker.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    left: -30px;
    top: 5px;
}
/* takeaway section ends */

/* video section starts */
.video {
    padding: 40px 150px;
}
.video .video__Content.vertical {
    height: 100%;
    display: var(--flex);
    flex-direction: column;
    justify-content: var(--center);
}
.video .video__Content h2 {
    font-size: 60px;
    color: var(--secondary-color);
    margin-bottom: 20px;
}
.video .video__Content h2 span {
    font-weight: var(--extra-bold);
    color: var(--primary-color);
}
.video .video__Content p {
    color: var(--secondary-color);
}
.video .video__Content video {
    width: 100%;
}
.video__Content .play-button-wrapper {
    position: var(--absolute);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.video__Content .play-button-wrapper img {
    width: 100%;
    max-width: 100px;
}
/* video section ends */


/* helpus starts */
.helpus {
    background-color: var(--bg-grey);
    padding: 60px 0;
}
.helpus .helpus__Content h2 {
    font-size: 60px;
    color: var(--secondary-color);
    font-weight: var(--extra-bold);
    margin-bottom: 50px;
}
.helpus .helpus__Content h2 span {
    color: var(--primary-color);
}
.helpus .helpus__Content p {
    color: var(--secondary-color);
}
.helpus .helpus__Content p a {
    color: var(--primary-color);
}
/* helpus ends */

/* result modal starts */
.resultModal .modal-dialog {
    max-width: 32%;
}
.resultModal .modal-content {
    border-radius: 4px;
}
.resultModal .modal-body {
    padding: 30px;
    box-shadow: 6px -9px 74px rgba(0, 0, 0, 0.11);
}
.resultModal .resultModal__Content h3 {
    font-size: 30px;
    color: var(--secondary-color);
    font-weight: var(--extra-bold);
}
.resultModal .resultModal__Content p {
    color: var(--secondary-color);
    margin-top: 20px;
}
.resultModal .resultModal__Content h2 {
    font-size: 40px;
    color: var(--secondary-color);
    font-weight: var(--extra-bold);
    margin: 50px 0;
}
.resultModal .resultModal__Content .btn-calc {
    background: linear-gradient(180deg, #23B432 0%, #12951E 100%);
    border-radius: 4px;
    color: var(--white-color);
    padding: 10px 20px;
    font-weight: var(--semi-bold);
}
#resultModalId .modal-body .btn-close {
    position: var(--absolute);
    top: 10px;
    right: 10px;
    box-shadow: none;
    outline: none;
    z-index: 1;
}
/* result modal ends */

/* error page starts */
.errorSection {
    padding: 100px 0;
}
.errorSection .errorImg {
    width: 100%;
    max-width: 60%;
    margin: 0 auto;
    display: block;
}
.errorSection a {
    display: inline-block;
    background: linear-gradient(180deg, #23B432 0%, #12951E 100%);
    color: var(--white-color);
    text-decoration: none;
    padding: 10px 30px;
    border-radius: 4px;
    font-weight: var(--semi-bold);
}
/* error page ends */


/* privacy starts */
.privacy {
    padding: 160px 0 80px;
}
.privacy__Content h2 {
    font-size: 50px;
    font-weight: var(--extra-bold);
    color: var(--secondary-color);
    margin-bottom: 50px;
}
.privacy__Content p {
    color: var(--secondary-color);
}
.privacy__Content ul li {
    margin-bottom: 15px;
    color: var(--secondary-color);
    list-style-type: var(--none);
    position: relative;
}
.privacy__Content ul li::before {
    position: absolute;
    content: " ";
    width: 20px;
    height: 20px;
    background-image: url(../img/marker.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    left: -30px;
    top: 5px;
}
/* privacy ends */

/* footer starts */
.footer {
    padding: 40px 0;
    background-color: var(--absolute);
}
.footer__Content img {
    width: 100%;
    max-width: 200px;
}
.footer__Content ul {
    padding: 0;
    text-align: var(--right);
    margin: 0;
}
.footer__Content ul li {
    list-style-type: var(--none);
    display: var(--inline-block);
}
.footer__Content ul li a {
    color: var(--secondary-color);
    padding: 0 10px;
    text-decoration: var(--none);
    transition: all 0.3s ease-in-out;
}
.footer__Content ul li a:hover {
    color: var(--primary-color);
}
/* footer ends */
